import { Link } from 'gatsby'
import React from 'react'
import { Box, Button as ButtonRebass, Flex } from 'rebass'
import styled from 'styled-components'
import { Paragraph } from './styled'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    font-family: inherit;
    font-size: inherit;
    border: none;
    outline: none;
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.base};
    border-radius: 2px;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
`

const Name = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 32%;
  }
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 32%;
  }
`
const Phone = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 32%;
  }
`

const Message = styled.textarea`
  width: 100%;
  margin: 0 0 1em 0;
  line-height: 1.6;
  min-height: 250px;
  resize: vertical;
`

const Submit = styled(ButtonRebass)`
  padding-left: 12px;
  padding-right: 12px;
  &:disabled {
    color: gray;
    background-color: #cecece;
    cursor: not-allowed;
  }
`

const StyledLink = styled(Link)`
  color: #666666;
`

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      tel: '',
      message: '',
      showModal: false,
      checked: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      tel: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Box mt={[2, 3]}>
        <Form
          name="contact"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot"
          overlay={this.state.showModal}
          onClick={this.closeModal}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              No llene este formulario:{' '}
              <input name="bot" onChange={this.handleInputChange} />
            </label>
          </p>

          <Name
            name="name"
            type="text"
            placeholder="Nombre completo"
            value={this.state.name}
            onChange={this.handleInputChange}
            required
          />
          <Email
            name="email"
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
          <Phone
            name="tel"
            type="tel"
            placeholder="Teléfono"
            value={this.state.phone}
            onChange={this.handleInputChange}
          />
          <Message
            name="message"
            type="text"
            placeholder="Mensaje"
            value={this.state.message}
            onChange={this.handleInputChange}
            required
          />
          <Flex width="100%" mb={2}>
            <Box
              as="input"
              mr={2}
              type="checkbox"
              name="conditions"
              value="Aceptar condiciones de envío de formulario"
              checked={this.state.checked}
              onChange={() =>
                this.setState(prevState => ({ checked: !prevState.checked }))
              }
              mb="0"
            />
            <Box as="label" width="100%" fontSize={1}>
              Acepto la{' '}
              <StyledLink to="politica-privacidad">
                política de privacidad
              </StyledLink>
            </Box>
          </Flex>
          <Submit
            as="input"
            name="submit"
            type="submit"
            value="Enviar"
            variant="primary"
            disabled={!this.state.checked}
          />

          <Modal visible={this.state.showModal}>
            <Paragraph>
              Gracias por ponerte en contacto con nosotros. Te responderemos con
              la mayor brevedad posible.
            </Paragraph>
            <ButtonRebass onClick={this.closeModal} variant="primary">
              Entendido
            </ButtonRebass>
          </Modal>
        </Form>
      </Box>
    )
  }
}

export default ContactForm
