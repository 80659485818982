import styled from 'styled-components'
import { Container } from '../../components/styled'
import { BoxText } from '../../components/styled/text'

export const StyledBoxText = styled(BoxText)`
  p {
    margin: 0;
    line-height: 1.2;
    a {
      color: #666666;
    }
  }
`

export const FirstContainer = styled(Container)`
  padding-bottom: 0;
`

export const SecondContainer = styled(Container)`
  padding-top: 0;
`
