import { graphql } from 'gatsby'
import React from 'react'

import SEO from '../components/SEO'
import { ContactTemplate } from '../templates/contact'

const ContactPage = ({ data }) => {
  const {
    contactStreet,
    contactVirtual,
    contactParagraph,
  } = data.contentfulConfiguracionGeneral
  const {
    metaTitle,
    metaDescription: { metaDescription },
  } = data.contentfulPaginas
  return (
    <React.Fragment>
      <SEO isPage title={metaTitle} description={metaDescription} />
      <ContactTemplate
        contactParagraph={contactParagraph}
        contactStreet={contactStreet}
        contactVirtual={contactVirtual}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    contentfulConfiguracionGeneral {
      contactParagraph {
        childMarkdownRemark {
          html
        }
      }
      contactStreet {
        childMarkdownRemark {
          html
        }
      }
      contactVirtual {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulPaginas(slug: { eq: "contacto" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
    }
  }
`

export default ContactPage
