import React from 'react'
import Helmet from 'react-helmet'
import { Box, Flex, Text } from 'rebass'

import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'
import {
  StyledBoxText,
  FirstContainer,
  SecondContainer,
} from './contact.styled'
import mapStyle from './map_style'

if (typeof window !== 'undefined') {
  window.initMap = () => {
    const center = { lat: 28.107326, lng: -15.419462 }
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center,
      zoom: 16,
      styles: mapStyle,
    })
    new window.google.maps.Marker({ position: center, map }) // eslint-disable-line no-new
  }
}

export const ContactTemplate = ({
  contactParagraph,
  contactStreet,
  contactVirtual,
}) => (
  <Layout>
    <Helmet>
      <script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDmZKZGvmN4xhqn7DA3_OpKD0dONcpC8oc&callback=initMap"
        async
        defer
      />
    </Helmet>

    <FirstContainer>
      <Text as="h1" fontSize={3}>
        CONTACTO
      </Text>
      <StyledBoxText
        fontSize={1}
        my={3}
        dangerouslySetInnerHTML={{
          __html: contactParagraph.childMarkdownRemark.html,
        }}
      />
      <Flex justifyContent="center" pt={4} pb={5} flexDirection="column">
        <StyledBoxText
          fontSize={1}
          py={2}
          px={4}
          css={{ P: { textAlign: 'center ' } }}
          dangerouslySetInnerHTML={{
            __html: contactStreet.childMarkdownRemark.html,
          }}
        />
        <StyledBoxText
          fontSize={1}
          py={2}
          px={4}
          css={{ P: { textAlign: 'center ' } }}
          dangerouslySetInnerHTML={{
            __html: contactVirtual.childMarkdownRemark.html,
          }}
        />
      </Flex>
    </FirstContainer>
    <Box id="map" mb={4} css={{ height: 420 }} />
    <SecondContainer>
      <ContactForm />
    </SecondContainer>
  </Layout>
)
